<template>
  <v-container fluid>
    <v-card>
      <v-row dense>
        <v-col cols="12" sm="3" class="pa-0">
          <v-sheet
            id="scrolling-techniques-7"
            class="overflow-x-hidden"
            max-height="90vh"
          >
            <v-row dense>
              <v-col align="center" justify="center">
                <vl-map
                  data-projection="EPSG:4326"
                  style="width: 100%; height: 100vh;"
                  :key="mapChange"
                  ref="map"
                >
                  <vl-view :zoom.sync="zoom" :center.sync="center"></vl-view>

                  <vl-layer-tile
                    v-for="(item, index) in $store.state.settings.maps"
                    :z-index="index"
                    :opacity="item.alpha / 100"
                    :key="item.id"
                  >
                    <vl-source-osm
                      v-if="(item.id === 'osm') & item.visible"
                    ></vl-source-osm>
                    <vl-source-xyz
                      v-if="(item.id === '2gis') & item.visible"
                      url="http://tile0.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1&ts=online_sd"
                    ></vl-source-xyz>
                    <vl-source-bingmaps
                      v-if="(item.id === 'bingMapsAerial') & item.visible"
                      :api-key="$store.state.settings.apiKeyBing"
                      imagery-set="Aerial"
                    ></vl-source-bingmaps>
                    <vl-source-bingmaps
                      v-if="
                        (item.id === 'bingMapsAerialWithLabels') & item.visible
                      "
                      :api-key="$store.state.settings.apiKeyBing"
                      imagery-set="Aerial"
                    ></vl-source-bingmaps>
                    <vl-source-xyz
                      v-if="(item.id === 'mapbox') & item.visible"
                      url="https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZGFnb3QiLCJhIjoiY2lveTNjbnRnMDBjOXZtbTJqNGNsNmk1NCJ9.bvTUlzXKG4A2ZejsF1v2Hw"
                    ></vl-source-xyz>
                    <vl-source-xyz
                      v-if="(item.id === 'arcgis') & item.visible"
                      url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                      :tile-load-function="arcgisLoad"
                    ></vl-source-xyz>
                  </vl-layer-tile>

                  <vl-layer-vector id="portalLayer">
                    <vl-source-vector :features.sync="featuresPlant" />
                    <PlantStyle />
                  </vl-layer-vector>
                  <vl-interaction-select
                    :features.sync="featuresPortal"
                    :layers="['portalLayer']"
                    @select="onSelect"
                    @unselect="onUnselect"
                  />
                </vl-map>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
        <v-col cols="12" sm="9" class="pt-0">
          <v-row>
            <v-col cols="12" sm="4">
              <v-select
                :items="typesObject"
                v-model="typeObject"
                item-text="name"
                item-value="id"
                placeholder="Тип объектов"
                return-object
                outlined
                dense
                hide-details
              />
            </v-col>
          </v-row>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Фильтр"
              single-line
              hide-details
            ></v-text-field>
            <v-btn
              icon
              outlined
              title="Обновить"
              color="primary"
              @click="initialize"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="desserts"
            :search="search"
            class="ma-2 elevation-1"
            height="64vh"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-dialog v-model="dialog" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row dense>
                          <v-card-text class="text-start"
                            >Сообщение от
                            {{ editedItem.full_name }}</v-card-text
                          >
                          <v-col>
                            <v-textarea
                              v-model="editedItem.body"
                              outlined
                              auto-grow
                            ></v-textarea>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-card-text class="text-start"
                            >Ответ администрации</v-card-text
                          >
                          <v-col>
                            <v-textarea
                              v-model="editedItem.reply"
                              outlined
                              auto-grow
                            ></v-textarea>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col>
                            <v-checkbox
                              :value="editedItem.status.status"
                              class="ma-0"
                              label="Обработана"
                              @change="changeStatus($event)"
                            ></v-checkbox
                          ></v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="save"
                        >Сохранить</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="destroy"
                        >Удалить</v-btn
                      >
                      <v-btn color="blue darken-1" text @click="close"
                        >Отменить</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.image="{ item }">
              <expandable-image class="image" :src="item.image">
              </expandable-image>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="coordinate(item)">
                mdi-eye
              </v-icon>
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize">Обновить</v-btn>
            </template>
          </v-data-table>
          <TreeAppeal
            v-if="typeTarget === 1 && passport"
            :properties="properties"
            :geoCoordinates="geoCoordinates"
            :district="district"
          />
          <BushAppeal
            v-if="typeTarget === 2 && passport"
            :properties="properties"
            :geoCoordinates="geoCoordinates"
            :district="district"
          />
          <CurtainTreeAppeal
            v-if="typeTarget === 3 && passport"
            :properties="properties"
            :geoCoordinates="geoCoordinates"
            :district="district"
          />
          <CurtainBushAppeal
            v-if="typeTarget === 4 && passport"
            :properties="properties"
            :geoCoordinates="geoCoordinates"
            :district="district"
          />
          <HedgeAppeal
            v-if="typeTarget === 5 && passport"
            :properties="properties"
            :geoCoordinates="geoCoordinates"
            :district="district"
          />
          <FlowerGardenAppeal
                  v-if="typeTarget === 6 && passport"
                  :properties="properties"
                  :geoCoordinates="geoCoordinates"
                  :district="district"
          />
          <LawnAppeal
            v-if="typeTarget === 7 && passport"
            :properties="properties"
            :geoCoordinates="geoCoordinates"
            :district="district"
          />
          <VinesAppeal
            v-if="typeTarget === 8 && passport"
            :properties="properties"
            :geoCoordinates="geoCoordinates"
            :district="district"
          />
        </v-col>
      </v-row>
    </v-card>
    <v-card>
      <v-snackbar v-model="snackbar" :timeout="timeout" top right outlined>
        {{ text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
            Закрыть
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import { URL_API } from "../settings/apiConnect";
import { URL } from "../settings/apiConnect";
import PlantStyle from "@/components/PlantStyle.vue";
import { fromLonLat } from "ol/proj";
import WKT from "ol/format/WKT";
import GeoJSON from "ol/format/GeoJSON";

import TreeAppeal from "@/passports/appealPassports/TreeAppeal.vue";
import BushAppeal from "@/passports/appealPassports/BushAppeal.vue";
import CurtainTreeAppeal from "@/passports/appealPassports/CurtainTreeAppeal.vue";
import CurtainBushAppeal from "@/passports/appealPassports/CurtainBushAppeal.vue";
import HedgeAppeal from "@/passports/appealPassports/HedgeAppeal.vue";
import LawnAppeal from "@/passports/appealPassports/LawnAppeal.vue";
import VinesAppeal from "@/passports/appealPassports/VinesAppeal.vue";
import FlowerGardenAppeal from "@/passports/appealPassports/FlowerGardenAppeal.vue";

export default {
  name: "RequestsPortalTab",
  data: () => ({
    mapChange: 0,
    zoom: 13,
    center: [76.889709, 43.238949],
    featuresPlant: [],
    featuresPortal: [],
    formatWKT: new WKT(),
    formatGeoJSON: new GeoJSON(),
    snackbar: false,
    text: "",
    timeout: 2000,
    typeTarget: 0,
    search: "",
    inputName: "",
    dialog: false,
    headers: [
      { text: "ID заявки", align: "start", value: "id", width: "5%" },
      { text: "ФИО", align: "start", value: "full_name", width: "5%" },
      { text: "Телефон", align: "start", value: "phone", width: "5%" },
      { text: "Email", align: "start", value: "email", width: "5%" },
      { text: "Сообщение от жителя", align: "start", value: "body", width: "25%" },
      { text: "Ответ администрации", align: "start", value: "reply", width: "25%" },
      { text: "Фото", align: "start", value: "image", width: "5%" },
      { text: "Статус", align: "start", value: "status.text", width: "5%" },
      { text: "Действия", align: "center", value: "actions", sortable: false, width: "100" }
    ],
    editedIndex: -1,
    editedItem: {
      body: "",
      reply: "",
      status: {}
    },
    defaultItem: {
      body: "",
      reply: "",
      status: {}
    },
    passport: false,
    properties: {},
    geoCoordinates: {},
    district: {},

    typesObject: [
      { id: "1", name: "Зел. насаждения" },
      { id: "21,22,23,24", name: "Вод. объекты" },
      { id: "3", name: "Фонтаны" },
      { id: "4", name: "Отходы" },
    ],
    typeObject: { id: "1", name: "Зел. насаждения" },
  }),

  computed: {
    ...mapGetters(["allAppeals"]),
    formTitle() {
      return this.editedIndex === -1 ? "Новый" : "Редактировать";
    },
    desserts() {
      let arr = [];
      this.$store.state.appeal.appeals.forEach(el => {
        console.log(el);
        arr.push({
          id: el.id,
          full_name: el.full_name,
          phone: el.phone,
          email: el.email,
          body: el.body,
          reply: el.reply,
          image: el.image ? URL + el.image : el.image,
          status:
            el.status === 0
              ? { status: 0, text: "Не обработана" }
              : { status: 1, text: "Обработана" },
          plant_id: el.plant_id,
          object_type: el.object_type,
        });
      });
      return arr;
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    typeObject(val) {
      this.objectType({ object_id: val.id });
    }
  },

  methods: {
    ...mapActions([
      "objectType",
      "getAppeals",
      "updateAppeal",
      "destroyAppeal",
      "getPlantById",
      "getDistrictByArea"
    ]),
    initialize() {
      this.getAppeals();
    },
    changeStatus(event) {
      if (event) {
        this.editedItem.status.status = 1;
      } else {
        this.editedItem.status.status = 0;
      }
    },
    save() {
      this.updateAppeal({
        id: this.editedItem.id,
        body: this.editedItem.body,
        reply: this.editedItem.reply,
        status: this.editedItem.status.status
      });
      this.$store.dispatch("getAppeals");
      this.close();
    },
    destroy() {
      this.destroyAppeal(this.editedItem.id);
      this.close();
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    async coordinate(item) {
      if(item.object_type === 1) {
        const plant = await this.getPlantById(item.plant_id);
        let coordinatesX;
        let coordinatesY;
        let type;
        type = plant.geometry.type;
        if (type === "Point") {
          coordinatesX = plant.geometry.coordinates[0];
          coordinatesY = plant.geometry.coordinates[1];
        } else if (type === "Polygon") {
          coordinatesX = plant.geometry.coordinates[0][0][0];
          coordinatesY = plant.geometry.coordinates[0][0][1];
        } else if (type === "LineString") {
          coordinatesX = plant.geometry.coordinates[0][0];
          coordinatesY = plant.geometry.coordinates[0][1];
        }

        var view = this.$refs.map.getView();
        view.setCenter(fromLonLat([coordinatesX, coordinatesY]));
        view.setZoom(21);

        let response = await Vue.axios.get(
          `${URL_API}plant/getbyfilter?id=${item.plant_id}`
        );
        let data = response["data"];
        this.featuresPlant = [];
        this.featuresPlant = data.features;
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async onSelect(feature) {
      let props;
      if (!this.passport) this.passport = true;
      props = await this.getPlantById(feature.json.properties.id);
      props.geometry = feature.json.geometry;
      this.typeTarget = props.type_id;
      setTimeout(() => {
        if (JSON.stringify(this.properties) === JSON.stringify({})) {
          this.properties = props;
        } else if (feature.json.properties)
          if (feature.json.properties.id) this.properties = props;
      }, 1);
      this.geoCoordinates = Object.assign(
        {},
        feature.json.geometry.coordinates
      );
      let geometry = this.formatGeoJSON.readFeature(feature.json.geometry);
      let wktRepresenation = this.formatWKT.writeFeature(geometry);
      this.district = await this.getDistrictByArea(wktRepresenation);
    },
    onUnselect() {
      this.passport = false;
      this.properties = {};
    },


  },

  components: {
    PlantStyle,
    TreeAppeal,
    BushAppeal,
    CurtainTreeAppeal,
    CurtainBushAppeal,
    HedgeAppeal,
    LawnAppeal,
    VinesAppeal,
    FlowerGardenAppeal
  },

  mounted() {
    const viewportMeta = document.createElement("meta");
    viewportMeta.name = "viewport";
    viewportMeta.content = "width=device-width, initial-scale=1";
    document.head.appendChild(viewportMeta);
  }
};
</script>
