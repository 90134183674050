<template>
  <v-navigation-drawer width="420" bottom right absolute>
    <v-col align="center">
      <h3>Паспорт объекта</h3>
      <v-row dense v-if="this.properties">
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Идентификационный номер</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ properties.id }}</v-card-text>
        </v-col>
      </v-row>
      <v-row dense v-if="bushesTarget">
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Порода кустарников</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ bushes }}</v-card-text>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Вид насаждений</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">Кустарник</v-card-text>
        </v-col>
      </v-row>
      <v-row dense v-if="categorieTarget">
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Категория насаждений</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ categories }}</v-card-text>
        </v-col>
      </v-row>
      <v-row dense v-if="ageTarget">
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Возраст</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ ageTarget }}</v-card-text>
        </v-col>
      </v-row>
      <v-row dense v-if="heigthTarget">
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Высота</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ heigthTarget }}</v-card-text>
        </v-col>
      </v-row>
      <v-row dense v-if="diameterTarget">
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Диаметр</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ diameterTarget }}</v-card-text>
        </v-col>
      </v-row>
      <v-row dense v-if="sanitaryTarget">
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Состояние</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ sanitary }}</v-card-text>
        </v-col>
      </v-row>
      <v-row dense v-if="verminTarget.length > 0">
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Вредители</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ vermins }}</v-card-text>
        </v-col>
      </v-row>
      <v-row dense v-if="diases.length > 0">
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Болезнь</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ diases }}</v-card-text>
        </v-col>
      </v-row>
      <v-row dense v-if="events.length > 0">
        <v-col cols="12" sm="6">
          <v-card-text class="text-start"
            >Хозяйственное мероприятие</v-card-text
          >
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ events }}</v-card-text>
        </v-col>
      </v-row>
      <v-row dense v-if="statuseTarget">
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Статус</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ statuses }}</v-card-text>
        </v-col>
      </v-row>
      <v-row dense v-if="serialNumTarget">
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Номер разрешения</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ serialNumTarget }}</v-card-text>
        </v-col>
      </v-row>
      <v-row dense v-if="plantingDateTarget">
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Дата посадки</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ plantingDateTarget }}</v-card-text>
        </v-col>
      </v-row>
      <v-row dense v-if="addedDateTarget">
        <v-col cols="12" sm="6">
          <v-card-text class="text-start">Дата добавления в реестр</v-card-text>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card-text class="text-end">{{ addedDateTarget }}</v-card-text>
        </v-col>
      </v-row>
      <v-row dense v-if="district">
        <v-col cols="12" sm="4">
          <v-card-text class="text-start">Район</v-card-text>
        </v-col>
        <v-col cols="12" sm="8">
          <v-card-text class="text-end">{{
            district ? district.name : ""
          }}</v-card-text>
        </v-col>
      </v-row>
    </v-col>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "AppealPassport",
  props: {
    properties: Object,
    geoCoordinates: Object,
    district: Object
  },

  data() {
    return {
      categorieTarget: null,
      ageTarget: null,
      heigthTarget: null,
      diameterTarget: null,
      sanitaryTarget: null,
      verminTarget: [],
      diasesTarget: [],
      eventsTarget: [],
      statuseTarget: null,
      serialNumTarget: null,
      plantingDateMenu: false,
      plantingDateTarget: null,
      addedDateMenu: false,
      addedDateTarget: null,
      inChargeTarget: null,
      bushesTarget: null
    };
  },

  computed: {
    categories() {
      let name = "";
      this.$store.state.categories.categoriesData.find(e => {
        if (e.id === this.categorieTarget) name = e.name;
      });
      return name;
    },
    sanitary() {
      let arr = [];
      this.$store.state.sanitary.sanitaryData.filter(el => {
        if (el.id >= 1 && el.id <= 6) arr.push(el);
      });
      let name = "";
      this.$store.state.sanitary.sanitaryData.find(e => {
        if (e.id === this.sanitaryTarget) name = e.name;
      });
      return name;
    },
    vermins() {
      let name = "";
      let arr = [];
      this.verminTarget.filter(e => arr.push(e.id));
      name = this.$store.state.vermins.verminsData
        .filter(e => arr.includes(e.id))
        .map(el => el.name)
        .join(", ");
      return name;
    },
    diases() {
      let name = "";
      let arr = [];
      this.diasesTarget.filter(e => arr.push(e.id));
      name = this.$store.state.diseases.diseasesData
        .filter(e => arr.includes(e.id))
        .map(el => el.name)
        .join(", ");
      return name;
    },
    events() {
      let name = "";
      let arr = [];
      this.eventsTarget.filter(e => arr.push(e.id));
      name = this.$store.state.events.eventsData
        .filter(e => arr.includes(e.id))
        .map(el => el.name)
        .join(", ");
      return name;
    },
    statuses() {
      let name = "";
      this.$store.state.statuses.statusesData.find(e => {
        if (e.id === this.statuseTarget) name = e.name;
      });
      return name;
    },
    bushes() {
      let name = "";
      this.$store.state.bushes.bushData.find(e => {
        if (e.id === this.bushesTarget) name = e.name;
      });
      return name;
    }
  },

  watch: {
    properties: function(newValue) {
      this.ageTarget = newValue.age;
      this.heigthTarget = newValue.height;
      this.diameterTarget = newValue.diameter;
      this.serialNumTarget = newValue.serial_number;
      this.sanitaryTarget = newValue.sanitary_id;
      this.categorieTarget = newValue.category_id;
      this.statuseTarget = newValue.status_id;
      this.inChargeTarget = newValue.in_charge;
      this.plantingDateTarget = newValue.planting_date;
      this.addedDateTarget = newValue.registry_date;
      this.verminTarget = newValue.vermin;
      this.diasesTarget = newValue.diseases;
      this.eventsTarget = newValue.events;
      if (newValue.bushes != null) {
        if (newValue.bushes.length) this.bushesTarget = newValue.bushes[0].id;
      }
    }
  },

  methods: {
    loadDataParent(value) {
      this.breedTarget = value;
    }
  }
};
</script>

<style scoped>
/* ::v-deep .v-input__slot {
  margin: 0;
  max-height: 32px;
  min-height: 32px !important;
  display: flex !important;
  align-items: center !important;
} */

::v-deep .v-btn:not(.v-btn--round).v-size--default {
  height: 32px;
  font-size: 12px;
}

::v-deep input,
p,
.v-input,
.v-card__text,
.v-text-field >>> label {
  font-size: 12px;
}

::v-deep .v-card__text {
  padding: 0;
}

::v-deep .v-select__selections {
  flex-wrap: nowrap;
}

::v-deep .v-text-field {
  padding-top: 0;
}

::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none;
  margin: 0;
}
</style>
