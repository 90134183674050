<template>
  <vl-style-func :factory="plantStyle" />
</template>

<script>
import { Circle, Fill, Stroke, Style, Icon } from "ol/style";
import Polygon from "ol/geom/Polygon";
import LineString from "ol/geom/LineString";

import treeGreenImg from "@/assets/tree_green.png";
import treeOrangeImg from "@/assets/tree_orange.png";
import treeBlueImg from "@/assets/tree_blue.png";
import treeBrownImg from "@/assets/tree_brown.png";
import treeYellowImg from "@/assets/tree_yellow.png";
import treePurpleImg from "@/assets/tree_purple.png";
import treeRedImg from "@/assets/tree_red.png";
import bushGreenImg from "@/assets/bush_green.png";
import bushOrangeImg from "@/assets/bush_orange.png";
import bushBlueImg from "@/assets/bush_blue.png";
import bushBrownImg from "@/assets/bush_brown.png";
import bushYellowImg from "@/assets/bush_yellow.png";
import bushPurpleImg from "@/assets/bush_purple.png";
import bushRedImg from "@/assets/bush_red.png";
import flowerGardenImg from "@/assets/flower_garden.png";
import cutdownImg from "@/assets/cutdown.png";
import lawnImg from "@/assets/lawn.png";
import redBookBlueImg from "@/assets/red_book_blue.png";
import redBookBrownImg from "@/assets/red_book_brown.png";
import redBookGreenImg from "@/assets/red_book_green.png";
import redBookOrangeImg from "@/assets/red_book_orange.png";
import redBookPurpleImg from "@/assets/red_book_purple.png";
import redBookRedImg from "@/assets/red_book_red.png";
import redBookYellowImg from "@/assets/red_book_yellow.png";
import pineBlueImg from "@/assets/pine_blue.png";
import pineBrownImg from "@/assets/pine_brown.png";
import pineGreenImg from "@/assets/pine_green.png";
import pineOrangeImg from "@/assets/pine_orange.png";
import pinePurpleImg from "@/assets/pine_purple.png";
import pineRedImg from "@/assets/pine_red.png";
import pineYellowImg from "@/assets/pine_yellow.png";
import warningImg from "@/assets/warning.png";

export default {
  methods: {
    plantStyle() {
      return feature => {
        const geom = feature.getGeometry();
        var cnv = document.createElement("canvas");
        var ctx = cnv.getContext("2d");
        var img = new Image();
        if (feature.get("status_id") === 19 || feature.get("status_id") === 20 || feature.get("status_id") === 21) {
          if (feature.get("redbook")) {
            return [
              new Style({
                image: new Icon({
                  src: redBookPurpleImg
                })
              })
            ];
          }
          if (feature.get("type_id") === 1) {
            if (feature.get("pine")) {
              return [
                new Style({
                  image: new Icon({
                    src: pinePurpleImg
                  })
                })
              ];
            }
            return [
              new Style({
                image: new Icon({
                  src: treePurpleImg
                })
              })
            ];
          } else if (feature.get("type_id") === 2) {
            return [
              new Style({
                image: new Icon({
                  src: bushPurpleImg
                })
              })
            ];
          }
        }
        if (
          feature.get("status_id") === 1 &&
          (feature.get("type_id") === 1 || feature.get("type_id") === 2)
        ) {
          return [
            new Style({
              image: new Icon({
                src: cutdownImg
              })
            })
          ];
        }
        if (
          feature.get("status_id") === 1 &&
          (feature.get("type_id") !== 1 || feature.get("type_id") !== 2)
        ) {
          return [
            new Style({
              fill: new Fill({ color: "#808080" }),
              stroke: new Stroke({
                color: "#808080",
                width: 3
              })
            })
          ];
        }
        if (
          feature.get("status_id") === null ||
          feature.get("status_id") !== 1
        ) {
          if (
            feature.get("type_id") === 1 &&
            (feature.get("redbook") || feature.get("pine"))
          ) {
            if (feature.get("redbook") && feature.get("sanitary_id") === 1) {
              var redBookGreen = new Style({
                image: new Icon({
                  src: redBookGreenImg
                })
              });
              return [redBookGreen];
            }
            if (feature.get("redbook") && feature.get("sanitary_id") === 2) {
              var redBookOrange = new Style({
                image: new Icon({
                  src: redBookOrangeImg
                })
              });
              return [redBookOrange];
            }
            if (feature.get("redbook") && feature.get("sanitary_id") === 3) {
              var redBookBlue = new Style({
                image: new Icon({
                  src: redBookBlueImg
                })
              });
              return [redBookBlue];
            }
            if (feature.get("redbook") && feature.get("sanitary_id") === 4) {
              var redBookBrown = new Style({
                image: new Icon({
                  src: redBookBrownImg
                })
              });
              return [redBookBrown];
            }
            if (feature.get("redbook") && feature.get("sanitary_id") === 5) {
              var redBookYellow = new Style({
                image: new Icon({
                  src: redBookYellowImg
                })
              });
              return [redBookYellow];
            }
            if (feature.get("redbook") && feature.get("sanitary_id") === 6) {
              var redBookRed = new Style({
                image: new Icon({
                  src: redBookRedImg
                })
              });
              return [redBookRed];
            }
            if (feature.get("pine") && feature.get("sanitary_id") === 1) {
              var pineGreen = new Style({
                image: new Icon({
                  src: pineGreenImg
                })
              });
              return [pineGreen];
            }
            if (feature.get("pine") && feature.get("sanitary_id") === 2) {
              var pineOrange = new Style({
                image: new Icon({
                  src: pineOrangeImg
                })
              });
              return [pineOrange];
            }
            if (feature.get("pine") && feature.get("sanitary_id") === 3) {
              var pineBlue = new Style({
                image: new Icon({
                  src: pineBlueImg
                })
              });
              return [pineBlue];
            }
            if (feature.get("pine") && feature.get("sanitary_id") === 4) {
              var pineBrown = new Style({
                image: new Icon({
                  src: pineBrownImg
                })
              });
              return [pineBrown];
            }
            if (feature.get("pine") && feature.get("sanitary_id") === 5) {
              var pineYellow = new Style({
                image: new Icon({
                  src: pineYellowImg
                })
              });
              return [pineYellow];
            }
            if (feature.get("pine") && feature.get("sanitary_id") === 6) {
              var pineRed = new Style({
                image: new Icon({
                  src: pineRedImg
                })
              });
              return [pineRed];
            }
          } else {
            if (
              feature.get("type_id") === 3 ||
              feature.get("type_id") === 4 ||
              feature.get("type_id") === 5 ||
              feature.get("type_id") === 8
            ) {
              var defaultGreen = new Style({
                fill: new Fill({ color: "#67CE57" }),
                stroke: new Stroke({
                  color: "#67CE57",
                  width: 3
                })
              });
              return [defaultGreen];
            }
            if (feature.get("type_id") === 6) {
              img.src = flowerGardenImg;
              var flowerGarden = new Style({
                fill: new Fill({ color: ctx.createPattern(img, "repeat") })
              });
              return [flowerGarden];
            }
            if (feature.get("type_id") === 7) {
              img.src = lawnImg;
              var lawn = new Style({
                fill: new Fill({ color: ctx.createPattern(img, "repeat") })
              });
              return [lawn];
            }
            if (
              feature.get("type_id") === 1 &&
              feature.get("sanitary_id") === 1
            ) {
              var treeGreen = new Style({
                image: new Icon({
                  src: treeGreenImg
                })
              });
              return [treeGreen];
            }
            if (
              feature.get("type_id") === 2 &&
              feature.get("sanitary_id") === 1
            ) {
              var bushGreen = new Style({
                image: new Icon({
                  src: bushGreenImg
                })
              });
              return [bushGreen];
            }
            if (
              feature.get("type_id") === 1 &&
              feature.get("sanitary_id") === 2
            ) {
              var treeOrange = new Style({
                image: new Icon({
                  src: treeOrangeImg
                })
              });
              return [treeOrange];
            }
            if (
              feature.get("type_id") === 2 &&
              feature.get("sanitary_id") === 2
            ) {
              var bushOrange = new Style({
                image: new Icon({
                  src: bushOrangeImg
                })
              });
              return [bushOrange];
            }
            if (
              feature.get("type_id") === 1 &&
              feature.get("sanitary_id") === 3
            ) {
              var treeBlue = new Style({
                image: new Icon({
                  src: treeBlueImg
                })
              });
              return [treeBlue];
            }
            if (
              feature.get("type_id") === 2 &&
              feature.get("sanitary_id") === 3
            ) {
              var bushBlue = new Style({
                image: new Icon({
                  src: bushBlueImg
                })
              });
              return [bushBlue];
            }
            if (
              feature.get("type_id") === 1 &&
              feature.get("sanitary_id") === 4
            ) {
              var treeBrown = new Style({
                image: new Icon({
                  src: treeBrownImg
                })
              });
              return [treeBrown];
            }
            if (
              feature.get("type_id") === 2 &&
              feature.get("sanitary_id") === 4
            ) {
              var bushBrown = new Style({
                image: new Icon({
                  src: bushBrownImg
                })
              });
              return [bushBrown];
            }
            if (
              feature.get("type_id") === 1 &&
              feature.get("sanitary_id") === 5
            ) {
              var treeYellow = new Style({
                image: new Icon({
                  src: treeYellowImg
                })
              });
              return [treeYellow];
            }
            if (
              feature.get("type_id") === 2 &&
              feature.get("sanitary_id") === 5
            ) {
              var bushYellow = new Style({
                image: new Icon({
                  src: bushYellowImg
                })
              });
              return [bushYellow];
            }
            if (
              feature.get("type_id") === 1 &&
              feature.get("sanitary_id") === 6
            ) {
              var treeRed = new Style({
                image: new Icon({
                  src: treeRedImg
                })
              });
              return [treeRed];
            }
            if (
              feature.get("type_id") === 2 &&
              feature.get("sanitary_id") === 6
            ) {
              var bushRed = new Style({
                image: new Icon({
                  src: bushRedImg
                })
              });
              return [bushRed];
            }
            if (
              (feature.get("type_id") === 1 || feature.get("type_id") === 2) &&
              (feature.get("sanitary_id") === 8 ||
                feature.get("sanitary_id") === 9 ||
                feature.get("sanitary_id") === 10)
            ) {
              var darkGreen = new Style({
                image: new Circle({
                  radius: 7,
                  fill: new Fill({ color: "#67CE57" })
                })
              });
              return [darkGreen];
            }
            if (
              feature.get("type_id") === null ||
              feature.get("sanitary_id") === null ||
              feature.get("redbook") === null
            ) {
              if (geom instanceof Polygon || geom instanceof LineString) {
                var warningDefault = new Style({
                  fill: new Fill({ color: "#FF5252" }),
                  stroke: new Stroke({
                    color: "#FF5252",
                    width: 3
                  })
                });
                return [warningDefault];
              } else {
                var warning = new Style({
                  image: new Icon({
                    src: warningImg
                  })
                });
                return [warning];
              }
            }
          }
        }
      };
    }
  }
};
</script>
